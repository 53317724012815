import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { FormikSelectInput, FormikTextInput } from "../../../../../components/Filters/FormikInputs";
import { useStyles } from './styles';
import { RubroDeMerchantGet } from 'services/hooli-services/RubroDeMerchantGet';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userCredentials } from '../../../../../recoilState/GlobalState';
import { TipoDeSociedadGet } from 'services/hooli-services/TipoDeSociedadGet';
import { CargoGet } from 'services/hooli-services/CargoGet';
function Identificacion({ formik, setIsOpen, merchantInfo }) {
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [tipoDeSociedad, setTipoDeSociedad] = useState([]);
    const [rubros, setRubros] = useState([])
    const [cargos, setCargos] = useState([])
    const styles = useStyles();

    useEffect(() => {
        Promise.all([getTipoSociedad(), getRubro(), getCargo()]);
    }, []);

    const dataFormik = {
        razonSocial: { label: "Razón Social*", placeholder: "" },
        tipoDePersona: { label: "Persona Física/Júridica", placeholder: "Física" },
        cuit: { label: "CUIT", placeholder: "30-22222222-8" },
        nombre: { label: "Nombre de tu comercio*", placeholder: "Grido" },
        tipoDeSociedad: { label: "Tipo de Sociedad", placeholder: "Sociedad Anónima" },
        rubro: { label: "Rubro de tu comercio", placeholder: "Heladeria" },
        cargo: { label: "Cargo", placeholder: "Representante legal" },
    }

    const getTipoSociedad = async () => {
        const response = await TipoDeSociedadGet(credentials);
        const mapResponse = response.result.map((soc) => {
            return {
                id: soc.tipoSocietarioId,
                label: soc.tipoSocietarioNombre
            }
        })
        setTipoDeSociedad(mapResponse);

    }

    const getRubro = async () => {
        const data = {
            rubroMerchantId: null,
            rubroMerchantCod: null,
            rubroMerchantEsAgencia: null,
            rubroMerchantADomicilio: null,
            rubroDeMerchantEsVISA: null,
        }
        const response = await RubroDeMerchantGet(credentials, data);
        const mapResponse = response.result.map((rub) => {
            return {
                id: rub.rubroMerchantId,
                label: rub.rubroMerchantNombre
            }
        })
        setRubros(mapResponse);

    }

    const getCargo = async () => {
        const data = {
            cargoId: null,
            cargoCod: null,
        }
        const response = await CargoGet(credentials, data);
        const mapResponse = response.result.map((car) => {
            return {
                id: car.cargoId,
                label: car.cargoNombre
            }
        })
        setCargos(mapResponse);
        console.log("Response cargo get", response);
    }


    return (
        <Grid width={"100%"} padding={"20px 0 50px 0"} md={8} lg={8}>
            <Box width={"100%"} className={styles.inputRazonSocial}>
                <FormikTextInput data={dataFormik} name={"razonSocial"} formik={formik} disabled={true} />
            </Box>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"tipoDePersona"} formik={formik} disabled={true} />
                <FormikSelectInput data={dataFormik} name={"tipoDeSociedad"} formik={formik} options={tipoDeSociedad} optionName="label" optionValue="id" optionKey="id" noSelect disabled={merchantInfo.merchantPFPJ === "F"} />
            </Box>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"cuit"} formik={formik} disabled={true} />
                <FormikSelectInput data={dataFormik} name={"rubro"} formik={formik} options={rubros} optionName="label" optionValue="id" optionKey="id" noSelect />
            </Box>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"nombre"} formik={formik} disabled={true} />
                <FormikSelectInput data={dataFormik} name={"cargo"} formik={formik} options={cargos} optionName="label" optionValue="id" optionKey="id" noSelect />
            </Box>
        </Grid>
    )
}

export { Identificacion }
